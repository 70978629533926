import React,{ useState,useRef } from "react";
import func from "./functionAdd";
import { useHistory } from "react-router-dom";
import json from '../json.json';
import Boxes from "./boxes";

export default function ServicesConsulting() {

    let history = useHistory();
    const goContact = () => { history.push("/contact")};
    const nameRef= useRef();
    const titleRef = useRef();
    const sendtoRef = useRef();
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    const handleChange = (event) => {
        event.persist();
        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    
    const [language, setLanguage] = useState();
    const handleLocalStorage = () => {
        let es = localStorage.getItem("language");
        if(es === "true"){
          setLanguage(false);
        }else{
          setLanguage(true);
        }
      };
      setTimeout(handleLocalStorage, 1);
      const [onModal1, setOnModal1] = useState(false);
    const [onTypeModal1, setOnTypeModal1] = useState("");
    let modalSend = onModal1 ? (<Boxes setOnModal={setOnModal1} type={onTypeModal1} data={""} />) :("");

    const modalEmail = () => {  setOnTypeModal1("modal"); setOnModal1(true); }; 
    const sendMail = async () =>{
        var param = {name:nameRef.current.value.replace(/,/g, ''), sendto:sendtoRef.current.value.replace(/,/g, ''), title: titleRef.current.value.replace(/,/g, '') };
        
        await func.EjectFetch('apimailer/index.php',param);
        modalEmail()
    }

    return (
        <>
        {modalSend}
            <section class="contenido">
                <article class="info-img">
                    <div class="informacion-header">
                        <h3 class="subtitulo-serivcio-int">{ language ? json.En.Service : json.Es.Service }</h3>
                        <h1 class="header-titulo">{ language ? json.En.titlePortfolioHome5 : json.Es.titlePortfolioHome5 }</h1>
                        <div class="contenedor-btn">
                            <button class="btn-servicio-contact" style={{cursor:"pointer"}} onClick={goContact}> {language ? json.En.contact : json.Es.contact }</button>
                        </div>
                    </div>
                    <div class="imagen-h">
                        <img src="css/images/consultoria-transformacion-digital.png" class="image-head" alt="wallia_transformacion-digital" />
                    </div>
                </article>
                <section class="banner-section">
                    <h2>{ language ? json.En.Whatis : json.Es.Whatis }</h2>
                    <p class="texto-que-es">{ language ? json.En.WhatisBI : json.Es.WhatisBI }</p>
                    <h2>{ language ? json.En.Howwedo : json.Es.Howwedo }</h2>
                    <p class="texto-que-es">{ language ? json.En.HowwedoS : json.Es.HowwedoS }</p>
                    <section class="steps-services">
                        <article class="pasos-servicio">
                            <article class="step-box">
                                <div class="number-step">1</div>
                                <div class="info-step">
                                    <p class="titulo-step">{ language ? json.En.Analizar1 : json.Es.Analizar1 }</p>
                                    <p class="texto-step">{ language ? json.En.Analizar : json.Es.Analizar }</p>
                                </div>
                            </article>
                            <article class="step-box">
                                <div class="number-step">2</div>
                                <div class="info-step">
                                    <p class="titulo-step">{ language ? json.En.Diseño1 : json.Es.Diseño1 }</p>
                                    <p class="texto-step">{ language ? json.En.Diseño : json.Es.Diseño }</p>
                                </div>
                            </article>
                        </article>
                        <article class="pasos-servicio">
                            <article class="step-box">
                                <div class="number-step">3</div>
                                <div class="info-step">
                                    <p class="titulo-step">{ language ? json.En.Desarrollo1 : json.Es.Desarrollo1 }</p>
                                    <p class="texto-step">{ language ? json.En.Desarrollo : json.Es.Desarrollo }</p>
                                </div>
                            </article>
                            <article class="step-box">
                                <div class="number-step">4</div>
                                <div class="info-step">
                                    <p class="titulo-step">{ language ? json.En.Implementar1 : json.Es.Implementar1 }</p>
                                    <p class="texto-step">{ language ? json.En.Implementar : json.Es.Implementar }</p>

                                </div>
                            </article>
                        </article>
                    </section>
                </section>
                <section class="contenido-ventajas">
                    <article class="contenido-interior-ventaja">
                        <div class="imagen-ventaja">
                            <img src="css/images/image-ventajas_.png" id="img-ventaja" alt="wallia_ventajas"/>
                        </div>
                        <div class="contenido-info-ventaja">
                            <h2>{ language ? json.En.h2MobileService : json.Es.h2MobileService }</h2>
                            <div class="iconos-ventaja">
                                <div class="caja-ventaja">
                                    <img src="css/images/icon-ventaja1_.png" class="icon-ventaja" alt="wallia_atencionaclientes"/>
                                    <p class="texto-ventaja">{ language ? json.En.pMoboleService : json.Es.pMoboleService }</p>
                                </div>
                                <div class="caja-ventaja">
                                    <img src="css/images/icon-ventaja2_.png" class="icon-ventaja" alt="wallia_calidad"/>
                                    <p class="texto-ventaja">{ language ? json.En.pMoboleService2 : json.Es.pMoboleService2 }</p>
                                </div>
                                <div class="caja-ventaja">
                                    <img src="css/images/icon-ventaja3_.png" class="icon-ventaja" alt="wallia_soporte_rapido"/>
                                    <p class="texto-ventaja">{ language ? json.En.pMoboleService3 : json.Es.pMoboleService3 }</p>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
                <section class="contact-section" style={{ "margin": "40px auto 40px;" }}>
                    <h2 class="title-green">{ language ? json.En.contact : json.Es.contact }</h2>
                    <form class="form-contact">
                        <div class="campo">
                            <label for="fname" class="label-contact">{ language ? json.En.name : json.Es.name }</label><br />
                            <input type="text" 
                            id="fname" 
                            name="fname"
                            class="campo-contact" 
                            ref={nameRef}
                            onChange={handleChange}/>
                        </div>
                        <div class="campo">
                            <label for="email" class="label-contact">{ language ? json.En.email : json.Es.email }</label><br />
                            <input type="email" 
                            id="email" 
                            name="email" 
                            class="campo-contact" 
                            ref={sendtoRef}
                            onChange={handleChange}/>
                        </div>
                        <div class="campo">
                            <label for="digital" class="label-contact">{ language ? json.En.title : json.Es.title }</label><br />
                            <select id="contact" 
                            name="digital"
                            ref={titleRef}
                            onChange={handleChange}>
                                <option value="app">{language ? json.En.appMobile : json.Es.appMobile}</option>
                                <option value="web">{language ? json.En.web : json.Es.web}</option>
                                <option value="solution">{language ? json.En.solition : json.Es.solition}</option>
                                <option value="adata">{ language ? json.En.data : json.Es.data }</option>
                            </select>
                        </div>
                        <div class="campo">
                        <input value={language ? json.En.send : json.Es.send } onClick={sendMail} class="enviar-datos" style={{cursor: "pointer"}}/>
                        </div>
                    </form>
                </section>
            </section>
        </>
    );
}