import React, { useState, useEffect, useRef } from "react";
import Boxes from "./boxes";
import func from "./functionAdd";
import json from "../json.json";

export default function Team() {
  const nameRef = useRef();
  const emailRef = useRef();
  const titleRef = useRef();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const modalErrorMail = () => {
    setOnTypeModal1("modal2");
    setOnModal1(true);
  };
  const sendMail = async () => {
    if (
      nameRef.current.value === "" ||
      emailRef.current.value === "" ||
      titleRef.current.value === ""
    ) {
      modalErrorMail();
      return;
    }
    var params = {
      name: nameRef.current.value.replace(/,/g, ""),
      sendto: emailRef.current.value.replace(/,/g, ""),
      title: titleRef.current.value.replace(/,/g, ""),
    };
    await func.EjectFetch("apimailer/index.php", params);
    modalEmail();
  };

  let st = { background: "#4282C3", color: "white", display: "block" };
  let st1 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
  let st2 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
  let st3 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
  const [style, setStyle] = useState(st);
  const [style1, setStyle1] = useState(st1);
  const [style2, setStyle2] = useState(st2);
  const [style3, setStyle3] = useState(st3);

  const [onModal, setOnModal] = useState(false);
  const [onTypeModal, setOnTypeModal] = useState("");
  let modalMsg = onModal ? (
    <Boxes setOnModal={setOnModal} type={onTypeModal} data={""} />
  ) : (
    ""
  );

  useEffect(() => {
    appMobile();
  }, []);

  const appMobile = () => {
    setOnTypeModal("app-mobile");
    setOnModal(true);
    let st = { background: "#3b55a1", color: "#fff", display: "block" };
    setStyle(st);
    let st1 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle1(st1);
    let st2 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle2(st2);
    let st3 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle3(st3);
  };
  const dotNet = () => {
    setOnTypeModal("dotnet");
    setOnModal(true);
    let st = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle(st);
    let st1 = { background: "#3b55a1", color: "#fff", display: "block" };
    setStyle1(st1);
    let st2 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle2(st2);
    let st3 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle3(st3);
  };
  const devBi = () => {
    setOnTypeModal("dev-bi");
    setOnModal(true);
    let st = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle(st);
    let st1 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle1(st1);
    let st2 = { background: "#3b55a1", color: "white", display: "block" };
    setStyle2(st2);
    let st3 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle3(st3);
  };
  const mobileNative = () => {
    setOnTypeModal("mobile-native");
    setOnModal(true);
    let st = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle(st);
    let st1 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle1(st1);
    let st2 = { background: "#F1F1F1", color: "#7F8F9A", display: "none" };
    setStyle2(st2);
    let st3 = { background: "#3b55a1", color: "white", display: "block" };
    setStyle3(st3);
  };

  const [language, setLanguage] = useState();
  const handleLocalStorage = () => {
    let es = localStorage.getItem("language");
    if (es === "true") {
      setLanguage(false);
    } else {
      setLanguage(true);
    }
  };
  setTimeout(handleLocalStorage, 1);

  const [onModal1, setOnModal1] = useState(false);
  const [onTypeModal1, setOnTypeModal1] = useState("");
  let modalSend = onModal1 ? (
    <Boxes setOnModal={setOnModal1} type={onTypeModal1} data={""} />
  ) : (
    ""
  );

  const modalEmail = () => {
    setOnTypeModal1("modal");
    setOnModal1(true);
  };

  return (
    <>
      {modalSend}
      <section class="contenido">
        <article class="info-img">
          <div class="informacion-header">
            <h1 class="header-titulo">
              {language ? json.En.headerTitleTeam : json.Es.headerTitleTeam}
            </h1>
            <h3 class="subtitulo-header">
              {language
                ? json.En.headerSubtitleTeam
                : json.Es.headerSubtitleTeam}
            </h3>
            <p class="texto-header">
              {language ? json.En.textHeaderTeam : json.Es.textHeaderTeam}
            </p>
          </div>
          <div class="imagen-h">
            <img
              src="css/images/img-header-equipo_.png"
              class="image-head"
              alt="wallia_equipo"
            />
          </div>
        </article>
        <section class="banner-section">
          <section class="vacantes">
            <h2>
              {language ? json.En.FooterWorkwithUs : json.Es.FooterWorkwithUs}
              <span
                class="text-cliente"
                style={{ color: "#303030", textA: "center" }}
              >
                <br />
                {language ? json.En.h2Team2 : json.Es.h2Team2}
              </span>
            </h2>
            <section class="contenido-vacantes" id="app-mobiles">
              <div id="app-mobiles">
                <article class="btn-vacantes">
                  <div
                    class="vacant-opcion vacant-activo"
                    onClick={appMobile}
                    style={{ background: style.background }}
                  >
                    <p
                      class="text-vacante text-vacan"
                      style={{ color: style.color }}
                    >
                      {language
                        ? json.En.textVacantTeam
                        : json.Es.textVacantTeam}
                    </p>
                  </div>
                  <div
                    class="vacant-opcion"
                    onClick={dotNet}
                    style={{ background: style1.background }}
                  >
                    <p class="text-vacante" style={{ color: style1.color }}>
                      {language
                        ? json.En.textVacantTeam2
                        : json.Es.textVacantTeam2}
                    </p>
                  </div>
                </article>
                <article class="btn-vacantes">
                  <div
                    class="vacant-opcion"
                    onClick={devBi}
                    style={{ background: style2.background }}
                  >
                    <p class="text-vacante" style={{ color: style2.color }}>
                      {language
                        ? json.En.textVacantTeam3
                        : json.Es.textVacantTeam3}
                    </p>
                  </div>
                  <div
                    class="vacant-opcion"
                    onClick={mobileNative}
                    style={{ background: style3.background }}
                  >
                    <p class="text-vacante" style={{ color: style3.color }}>
                      {language
                        ? json.En.textVacantTeam4
                        : json.Es.textVacantTeam4}
                    </p>
                  </div>
                </article>
                {modalMsg}
              </div>
            </section>
          </section>
        </section>
        <section class="contact-section">
          <h2>{language ? json.En.contact : json.Es.contact}</h2>
          <form class="form-contact">
            <div class="campo">
              <label for="fname" class="label-contact">
                {language ? json.En.name : json.Es.name}
              </label>
              <br />
              <input
                type="text"
                id="fname"
                name="fname"
                class="campo-contact"
                ref={nameRef}
                onChange={handleChange}
              />
            </div>
            <div class="campo">
              <label for="email" class="label-contact">
                {language ? json.En.email : json.Es.email}
              </label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                class="campo-contact"
                ref={emailRef}
                onChange={handleChange}
              />
            </div>
            <div class="campo">
              <label for="digital" class="label-contact">
                {language ? json.En.title : json.Es.title}
              </label>
              <br />
              <select
                id="contact"
                name="digital"
                ref={titleRef}
                onChange={handleChange}
              >
                <option value="app">
                  {language ? json.En.appMobile : json.Es.appMobile}
                </option>
                <option value="web">
                  {language ? json.En.web : json.Es.web}
                </option>
                <option value="solution">
                  {language ? json.En.solition : json.Es.solition}
                </option>
                <option value="adata">
                  {language ? json.En.data : json.Es.data}
                </option>
              </select>
            </div>
            <div class="campo">
              <input
                value={language ? json.En.send : json.Es.send}
                onClick={sendMail}
                class="enviar-datos"
                style={{ cursor: "pointer" }}
              />
            </div>
          </form>
          <section class="contenido-clientes" style={{ margin: "90px 0 0px" }}>
            <h2>{language ? json.En.h2Clients1 : json.Es.h2Clients1}</h2>
            <article class="clientes">
              <img
                src="css/images/cliente01_.png"
                class="cliente-logo"
                alt="walliasaba"
              />
              <img
                src="css/images/cliente10.png"
                class="cliente-logo"
                alt="walliataf"
              />
              <img
                src="css/images/cliente03_.png"
                class="cliente-logo"
                alt="walliapetco"
              />
              <img
                src="css/images/cliente09.png"
                class="cliente-logo"
                alt="walliaguess"
              />
              <img
                src="css/images/cliente05_.png"
                class="cliente-logo"
                alt="walliashake"
              />
              <img
                src="css/images/cliente06_.png"
                class="cliente-logo"
                alt="walliacalvinklein"
              />
              <img
                src="css/images/cliente07_.png"
                class="cliente-logo"
                alt="walliatommy"
              />
              <img
                src="css/images/cliente08_.png"
                class="cliente-logo"
                alt="walliabath"
              />
            </article>
          </section>
        </section>
      </section>
    </>
  );
}
