import React, { useState, useRef } from "react";
import func from "./functionAdd";
import { useHistory } from "react-router-dom";
import json from "../json.json";
import Boxes from "./boxes";

export default function Services() {
  let history = useHistory();
  const goAppMobile = () => {
    history.push("/mobile-service");
  };
  const goDevWeb = () => {
    history.push("/web-service");
  };
  const goDevBi = () => {
    history.push("/service-bi");
  };
  const goConsulting = () => {
    history.push("/service-consulting");
  };
  const nameRef = useRef();
  const titleRef = useRef();
  const sendtoRef = useRef();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [language, setLanguage] = useState();
  const handleLocalStorage = () => {
    let es = localStorage.getItem("language");
    if (es === "true") {
      setLanguage(false);
    } else {
      setLanguage(true);
    }
  };
  setTimeout(handleLocalStorage, 1);

  const [onModal1, setOnModal1] = useState(false);
  const [onTypeModal1, setOnTypeModal1] = useState("");
  let modalSend = onModal1 ? (
    <Boxes setOnModal={setOnModal1} type={onTypeModal1} data={""} />
  ) : (
    ""
  );

  const modalEmail = () => {
    setOnTypeModal1("modal");
    setOnModal1(true);
  };
  const modalErrorMail = () => {
    setOnTypeModal1("modal2");
    setOnModal1(true);
  };
  const sendMail = async () => {
    if (
      nameRef.current.value === "" ||
      sendtoRef.current.value === "" ||
      titleRef.current.value === ""
    ) {
      modalErrorMail();
      return;
    }
    var param = {
      name: nameRef.current.value.replace(/,/g, ""),
      sendto: sendtoRef.current.value.replace(/,/g, ""),
      title: titleRef.current.value.replace(/,/g, ""),
    };
    await func.EjectFetch("apimailer/index.php", param);
    modalEmail();
  };
  return (
    <>
      {modalSend}
      <section class="contenido">
        <article class="info-img">
          <div class="informacion-header">
            <h1 class="header-titulo">
              {language ? json.En.MenuServicios : json.Es.MenuServicios}
            </h1>
            <h3 class="subtitulo-header">
              {language
                ? json.En.headerSubtitleServices
                : json.Es.headerSubtitleServices}
            </h3>
            <p class="texto-header">
              {language
                ? json.En.headerTextServices
                : json.Es.headerTextServices}
            </p>
          </div>
          <div class="imagen-h">
            <img
              src="css/images/img-header-servicios.png"
              class="image-head"
              style={{ width: "80%;" }}
              alt="wallia_services"
            />
          </div>
        </article>
        <section class="banner-section">
          <h2>{language ? json.En.h2Serv : json.Es.h2Serv}</h2>
          <section class="contenido-services">
            <article class="caja-servicios1">
              <div class="servicios-contenido">
                <img
                  src="css/images/icon-service-uno_.png"
                  class="icono-servicio1"
                  alt="wallia_desarrollo_mobile"
                />
                <p class="texto-servicio-caja">
                  {language ? json.En.textServices : json.Es.textServices}
                  <br />{" "}
                  {language ? json.En.textServices1 : json.Es.textServices1}
                </p>
                <button onClick={goAppMobile} class="mas-info-servicio">
                  {language ? json.En.textServices3 : json.Es.textServices3}
                </button>
              </div>
              <div class="servicios-contenido">
                <img
                  src="css/images/icon-service-2_.png"
                  class="icono-servicio"
                  alt="wallia_desarrolla_web"
                />
                <p class="texto-servicio-caja">
                  {language ? json.En.textServices : json.Es.textServices}
                  <br />
                  Web
                </p>
                <button class="mas-info-servicio" onClick={goDevWeb}>
                  {language ? json.En.textServices3 : json.Es.textServices3}
                </button>
              </div>
            </article>
            <article class="caja-servicios1">
              <div class="servicios-contenido">
                <img
                  src="css/images/icon-service-3_.png"
                  class="icono-servicio"
                  alt="wallia_desarrollo_bi"
                />
                <p class="texto-servicio-caja">
                  {language ? json.En.textServices : json.Es.textServices}
                  <br />
                  {language ? json.En.textServices4 : json.Es.textServices4}
                </p>
                <button class="mas-info-servicio" onClick={goDevBi}>
                  {language ? json.En.textServices3 : json.Es.textServices3}
                </button>
              </div>
              <div class="servicios-contenido">
                <img
                  src="css/images/icon-service-4_.png"
                  class="icono-servicio"
                  alt="wallia_tranformacion_digital"
                />
                <p class="texto-servicio-caja">
                  {language ? json.En.textServices5 : json.Es.textServices5}
                  <br />
                  {language ? json.En.textServices6 : json.Es.textServices6}
                </p>
                <button class="mas-info-servicio" onClick={goConsulting}>
                  {language ? json.En.textServices3 : json.Es.textServices3}
                </button>
              </div>
            </article>
          </section>
        </section>
        <section class="contact-section" style={{ margin: "40px auto 40px;" }}>
          <h2 class="title-green">
            <h2 class="title-green">
              {language ? json.En.contact : json.Es.contact}
            </h2>
          </h2>
          <form class="form-contact">
            <div class="campo">
              <label for="fname" class="label-contact">
                {language ? json.En.name : json.Es.name}
              </label>
              <br />
              <input
                type="text"
                id="fname"
                name="fname"
                class="campo-contact"
                ref={nameRef}
                onChange={handleChange}
              />
            </div>
            <div class="campo">
              <label for="email" class="label-contact">
                {language ? json.En.email : json.Es.email}
              </label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                class="campo-contact"
                ref={sendtoRef}
                onChange={handleChange}
              />
            </div>
            <div class="campo">
              <label for="digital" class="label-contact">
                {language ? json.En.title : json.Es.title}
              </label>
              <br />
              <select
                id="contact"
                name="digital"
                ref={titleRef}
                onChange={handleChange}
              >
                <option value="app">
                  {language ? json.En.appMobile : json.Es.appMobile}
                </option>
                <option value="web">
                  {language ? json.En.web : json.Es.web}
                </option>
                <option value="solution">
                  {language ? json.En.solition : json.Es.solition}
                </option>
                <option value="adata">
                  {language ? json.En.data : json.Es.data}
                </option>
              </select>
            </div>
            <div class="campo">
              <input
                value={language ? json.En.send : json.Es.send}
                onClick={sendMail}
                class="enviar-datos"
                style={{ cursor: "pointer" }}
              />
            </div>
          </form>

          <section class="contenido-clientes" style={{ margin: "90px 0 0px" }}>
            <h2>{language ? json.En.h2Clients1 : json.Es.h2Clients1}</h2>
            <article class="clientes">
              <img
                src="css/images/cliente01_.png"
                class="cliente-logo"
                alt="walliasaba"
              />
              <img
                src="css/images/cliente10.png"
                class="cliente-logo"
                alt="walliataf"
              />
              <img
                src="css/images/cliente03_.png"
                class="cliente-logo"
                alt="walliapetco"
              />
              <img
                src="css/images/cliente09.png"
                class="cliente-logo"
                alt="walliaguess"
              />
              <img
                src="css/images/cliente05_.png"
                class="cliente-logo"
                alt="walliashake"
              />
              <img
                src="css/images/cliente06_.png"
                class="cliente-logo"
                alt="walliacalvinklein"
              />
              <img
                src="css/images/cliente07_.png"
                class="cliente-logo"
                alt="walliatommy"
              />
              <img
                src="css/images/cliente08_.png"
                class="cliente-logo"
                alt="walliabath"
              />
            </article>
          </section>
        </section>
      </section>
    </>
  );
}
