import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  NavLink,
  Link,
} from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import Home from "./Home";
import Contact from "./Contact";
import Clients from "./Clients";
import Team from "./Team";
import Services from "./Services";
import mobileService from "./MobileService";
import ServicesConsulting from "./ServicesConsulting";
import ServiceBi from "./serviceBI";
import ServiceWeb from "./ServiceWeb";
import json from "../json.json";
import { color } from "@mui/system";
import Interns from "./Interns";

function App() {
  let st = { color: "#4282C3 !important;" };
  const [style, setStyle] = useState(st);

  /*  useEffect((st)=>{
    document.getElementById("clientes").className=('nav-list active-menu')
    document.getElementById("teams").className=('nav-list')
    }   
    )

    useEffect((st)=>{
      document.getElementById("clientes").className=('nav-list ')
      document.getElementById("teams").className=('nav-list active-menu')
      }   
      )*/

  console.log(window.location.pathname);

  const home = () => {
    /* const currentURL = window.location.href;
    if(currentURL === 'http://localhost:3000/'){
    console.log("home");
    let st = {color: "#4282C3 !important;", fontWeight: "800;"}
  } */
  };
  const [language, setLanguage] = useState(false);
  useEffect(() => {
    localStorage.setItem("language", "true");
  }, []);

  const ingles = () => {
    console.log("hola");
    let es = localStorage.getItem("language");
    if (es === "true") {
      localStorage.setItem("language", "false");
      setLanguage(true);
    } else {
      localStorage.setItem("language", "true");
      setLanguage(false);
    }
  };

  return (
    <>
      <section class="contenido">
        <Router>
          <section class="navigation">
            <div class="nav-container ">
              <div class="brand">
                <a href="/">
                  <img
                    src="css/images/logo-wallia-new_.png"
                    id="logo-wallia"
                    alt="walliadigital"
                  />
                </a>
              </div>
              <nav>
                <div class="nav-mobile">
                  <a id="nav-toggle" href="#!">
                    <span></span>
                  </a>
                </div>
                <ul class="nav-list">
                  <li>
                    {/* <a href="/" onClick={home} style={{color:(window.location.pathname==="/"?"#4282C3 !important;":"")}}>{ language ? json.En.MenuNos : json.Es.MenuNos }</a> */}
                    <NavLink to="/" exact activeClassName="active-menu">
                      {" "}
                      {language ? json.En.MenuNos : json.Es.MenuNos}
                    </NavLink>{" "}
                  </li>
                  <li>
                    {" "}
                    <a>
                      {language ? json.En.MenuServicios : json.Es.MenuServicios}
                    </a>
                    <ul class="nav-dropdown">
                      {/* <li> <a href="/services">Full Servicios</a> </li> */}
                      <li>
                        <NavLink to="/services" activeClassName="active-menu">
                          {language
                            ? json.En.MenuServiciosF
                            : json.Es.MenuServiciosF}
                        </NavLink>
                      </li>
                      <li>
                        {" "}
                        {/* <a 
              href="/mobile-service"style={{color:(window.location.pathname==="/mobile-service"?"#4282C3 !important;":"")}}>
                { language ? json.En.MenuDevMobile : json.Es.MenuDevMobile }</a> */}
                        <NavLink
                          to="/mobile-service"
                          activeClassName="active-menu"
                        >
                          {" "}
                          {language
                            ? json.En.MenuDevMobile
                            : json.Es.MenuDevMobile}
                        </NavLink>{" "}
                      </li>

                      <li>
                        {" "}
                        {/* <a href="/web-service"style={{color:(window.location.pathname==="/web-service"?"#4282C3 !important;":"")}}>{ language ? json.En.MenuDevWev : json.Es.MenuDevWev }</a> */}
                        <NavLink
                          to="/web-service"
                          activeClassName="active-menu"
                        >
                          {" "}
                          {language ? json.En.MenuDevWev : json.Es.MenuDevWev}
                        </NavLink>{" "}
                      </li>

                      <li>
                        {/* <a href="/service-bi"style={{color:(window.location.pathname==="/service-bi"?"#4282C3 !important;":"")}}>{ language ? json.En.MenuBi : json.Es.MenuBi }</a> */}
                        <NavLink to="/service-bi" activeClassName="active-menu">
                          {" "}
                          {language ? json.En.MenuBi : json.Es.MenuBi}
                        </NavLink>{" "}
                      </li>
                      <li>
                        {/*  <a href="/service-consulting"style={{color:(window.location.pathname==="/service-consulting"?"#4282C3 !important;":"")}}>{ language ? json.En.MenuConsulting : json.Es.MenuConsulting }</a> */}
                        <NavLink
                          to="/service-consulting"
                          activeClassName="active-menu"
                        >
                          {language
                            ? json.En.MenuConsulting
                            : json.Es.MenuConsulting}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    {/* <a id="clientes"  href="/clients" onClick={App}>{ language ? json.En.MenuClients : json.Es.MenuClients }</a> */}
                    <NavLink to="/clients" activeClassName="active-menu">
                      {" "}
                      {language ? json.En.MenuClients : json.Es.MenuClients}
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    {/* <a id="teams" href="/team" onClick={App} >{ language ? json.En.MenuTeam : json.Es.MenuTeam }</a> */}
                    <NavLink to="/team" activeClassName="active-menu">
                      {" "}
                      {language ? json.En.MenuTeam : json.Es.MenuTeam}
                    </NavLink>{" "}
                  </li>
                  <li>
                    {/*  <a  href="/contact"style={{color:(window.location.pathname==="/contact"?"#4282C3 !important;":"")}} >{ language ? json.En.MenuContact : json.Es.MenuContact }</a> */}
                    <NavLink to="/contact" activeClassName="active-menu">
                      {" "}
                      {language ? json.En.MenuContact : json.Es.MenuContact}
                    </NavLink>{" "}
                  </li>
                  <li onClick={ingles} style={{ cursor: "pointer" }}>
                    <a>
                      {language ? (
                        <img
                          src="css/images/spanish-icon.png"
                          id="ingles"
                          alt="walliaMexico"
                        />
                      ) : (
                        <img
                          src="css/images/english_.png"
                          id="ingles"
                          alt="walliaestados"
                        />
                      )}
                      {language ? json.Es.language : json.En.language}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </section>

          <Switch>
            <Route exact path="/" children={<Home />} />
            <Route path="/service-consulting" component={ServicesConsulting} />
            <Route path="/contact" component={Contact} />
            <Route path="/service-bi" component={ServiceBi} />
            <Route path="/services" component={Services} />
            <Route path="/web-service" component={ServiceWeb} />
            <Route path="/mobile-service" component={mobileService} />
            <Route path="/clients" component={Clients} />
            <Route path="/team" component={Team} />
            <Route path="/slider" component={App} />
            <Route path="/interns" component={Interns} />
          </Switch>
        </Router>
      </section>
      <footer class="contenido-foot">
        <div class="cajas-foot">
          <div class="caja-uno-foot">
            <img
              src="css/images/logo-foot-white_.png"
              class="logo-white-foot"
              alt="walliadigitalfooter"
            />
            <p class="texto-foot-derechos">
              Copyright ©2021 Wallia.io
              <br />
              All rights reserved
            </p>
          </div>
          <div class="caja-uno-foot">
            <p class="list-foot">
              <a href="/" style={{ cursor: "pointer" }} class="page-foot">
                {language ? json.En.MenuNos : json.Es.MenuNos}
              </a>
              <br />
              <a href="/services" class="page-foot">
                {language ? json.En.MenuServicios : json.Es.MenuServicios}
              </a>
              <br />
              <a href="/clients" class="page-foot">
                {language ? json.En.MenuClients : json.Es.MenuNos}
              </a>
              <br />
              <a href="/team" class="page-foot">
                {language ? json.En.MenuTeam : json.Es.MenuTeam}
              </a>
            </p>
          </div>
          <div class="caja-uno-foot">
            <p class="subtitle-foot">
              {language ? json.En.FooterWorkwithUs : json.Es.FooterWorkwithUs}
            </p>
            <div class="caja-social-icon-foot">
              <img
                onClick={() =>
                  (window.location.href =
                    "https://www.facebook.com/mx.wallia.io")
                }
                src="css/images/icons-wallia-social-f.png"
                class="icon-social-foot"
                alt="walliaFace"
              />
              <img
                onClick={() =>
                  (window.location.href =
                    "https://www.linkedin.com/company/wallia-io/")
                }
                src="css/images/icons-wallia-social-l.png"
                class="icon-social-foot"
                alt="wallialink"
              />
              <img
                onClick={() =>
                  (window.location.href = "https://www.instagram.com/wallia.io")
                }
                src="css/images/icons-wallia-social-i.png"
                class="icon-social-foot"
                alt="walliainstagram"
              />
            </div>
          </div>
          <div class="caja-uno-foot">
            <div class="btn-contactanos">
              <a
                href="/contact"
                style={{ "text-decoration": "none", color: "white" }}
              >
                {language ? json.En.contact : json.Es.contact}
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
