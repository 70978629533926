import React, { useEffect, useState } from "react";
import "./hero.css";

const Hero = () => {
  const titles = [
    "Arquitectos de Soluciones Digitales",
    "Expertos en Ecommerce",
    "Líderes en Transformación Digital",
  ];

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let currentTitleIndex = 0;

  const [currentTitle, setCurrentTitle] = useState(titles[0]);

  useEffect(() => {
    const titleElement = document.getElementById("dynamic-title");

    function randomCharacter() {
      return characters[Math.floor(Math.random() * characters.length)];
    }

    function scrambleText(finalText, index = 0) {
      if (index < finalText.length) {
        let scrambledText = "";

        for (let i = 0; i < finalText.length; i++) {
          if (i < index) {
            scrambledText += `<span class="opacity-100 transition-opacity duration-300">${finalText[i]}</span>`;
          } else {
            if (i <= index + 2) {
              scrambledText += `<span class="opacity-30 transition-opacity duration-300">${randomCharacter()}</span>`;
            }
            if (i <= index + 1) {
              scrambledText += `<span class="opacity-20 transition-opacity duration-300">${randomCharacter()}</span>`;
            }
          }
        }

        titleElement.innerHTML = scrambledText;

        setTimeout(() => scrambleText(finalText, index + 1), 40);
      } else {
        titleElement.innerHTML = finalText
          .split("")
          .map(
            (char) =>
              `<span class="opacity-100 transition-opacity duration-300">${char}</span>`
          )
          .join("");
        setTimeout(() => changeTitle(), 3000);
      }
    }

    function changeTitle() {
      currentTitleIndex = (currentTitleIndex + 1) % titles.length;
      scrambleText(titles[currentTitleIndex]);
    }

    setTimeout(() => scrambleText(titles[currentTitleIndex]), 100);
  }, [currentTitle]);

  return (
    <section className="hero-section">
      <img
        src="	https://wallia.io/css/images/banner-wallia.jpg"
        alt="Imagen de fondo sección Hero"
        className="hero-image"
      />
      <article className="hero-content">
        <h1 className="hero-title uppercase text-center text-white">
          Somos{" "}
          <span id="dynamic-title" className="text-wallia-primary font-bold">
            Arquitectos de Soluciones
          </span>
        </h1>

        <h2 className="hero-subtitle text-white">
          WALLIA es una consultora de TI boutique con una trayectoria exitosa
          trabajando con marcas premium en México y Latinoamérica.
        </h2>
      </article>
    </section>
  );
};

export default Hero;
