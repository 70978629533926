import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { async } from "validate.js";
import Boxes from "./boxes";
import func from "./functionAdd";
import json from "../json.json";
import { Swiper, SwiperSlide } from "swiper/react";

//Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import required modules
import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";

export default function Clients() {
  SwiperCore.use([Autoplay, Navigation, Scrollbar]);
  const swiperRef = useRef(null);
  const nameRef = useRef();
  const titleRef = useRef();
  const sendtoRef = useRef();
  const [onModal, setOnModal] = useState(false);
  const [onActiveSlider, setOnActiveSlider] = useState(0);
  const [onTypeModal, setOnTypeModal] = useState("");
  var modal = "";
  let modalMsg = onModal ? (
    <Boxes setOnModal={setOnModal} type={onTypeModal} data={""} />
  ) : (
    ""
  );

  const slideToSlide = (slideIndex) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(slideIndex);
    }
  };

  const [onModal1, setOnModal1] = useState(false);
  const [onTypeModal1, setOnTypeModal1] = useState("");
  let modalSend = onModal1 ? (
    <Boxes setOnModal={setOnModal1} type={onTypeModal1} data={""} />
  ) : (
    ""
  );

  const modalEmail = () => {
    setOnTypeModal1("modal");
    setOnModal1(true);
  };

  const modalErrorMail = () => {
    setOnTypeModal1("modal2");
    setOnModal1(true);
  };

  const [language, setLanguage] = useState();
  const handleLocalStorage = () => {
    let es = localStorage.getItem("language");
    if (es === "true") {
      setLanguage(false);
    } else {
      setLanguage(true);
    }
  };
  setTimeout(handleLocalStorage, 1);

  useEffect(() => {}, []);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const modalWrongMail = () => {
    setOnTypeModal1("modal3");
    setOnModal1(true);
  };

  const sendMail = async () => {
    if(!sendtoRef.current.value.includes("@") || !sendtoRef.current.value.includes(".")){
      return modalWrongMail();
    }  
    if (
      nameRef.current.value === "" ||
      sendtoRef.current.value === "" ||
      titleRef.current.value === ""
    ) {
      setFormState((formState) => ({
        ...formState,
        errors: {
          ...formState.errors,
          [nameRef.current.name]: nameRef.current.value === "",
          [sendtoRef.current.name]: sendtoRef.current.value === "",
          [titleRef.current.name]: titleRef.current.value === "",
        },
      }));
      modalErrorMail();
    } else {
      var param = {
        name: nameRef.current.value.replace(/,/g, ''),
        sendto: sendtoRef.current.value.replace(/,/g, ''),
        title: titleRef.current.value.replace(/,/g, ''),
      };
      await func.EjectFetch("apimailer/index.php", param);
      modalEmail();
    }
  };

  return (
    <>
      {modalSend}
      <section class="contenido">
        <article class="info-img">
          <div class="informacion-header">
            <h1 class="header-titulo">
              {language
                ? json.En.headerTitleClients
                : json.Es.headerTitleClients}
            </h1>
            <h3 class="subtitulo-header">
              {language
                ? json.En.headerSubtitleClients
                : json.Es.headerSubtitleClients}
            </h3>
            <p class="texto-header">
              {language ? json.En.headerTextClients : json.Es.headerTextClients}
            </p>
          </div>
          <div class="imagen-h">
            <img
              src="css/images/img-header-clientes_.png"
              class="image-head"
              alt="walliaclientes"
            />
          </div>
        </article>
        <section class="banner-section">
          <section class="tab-cliente">
            <h2>{language ? json.En.h2Clients1 : json.Es.h2Clients1}</h2>

            <article class="tabs-clientes">
              <article class="barra-marca">
                {/* <img src="css/images/flecha-izquierda_.png" class="flecha-cliente-left" /> */}

                <div
                  id="tab0"
                  className="marca-tab1 active-tab"
                  onClick={() => slideToSlide(0)}
                >
                  <img
                    src="css/images/marca-petco_.png"
                    className="mark-tab"
                    alt="walliapetco"
                  />
                </div>
                <div
                  id="tab1"
                  className="marca-tab2"
                  onClick={() => slideToSlide(1)}
                >
                  <img
                    src="css/images/sha.png"
                    className="mark-tab"
                    alt="walliashake"
                  />
                </div>
                <div
                  id="tab2"
                  className="marca-tab3"
                  onClick={() => slideToSlide(2)}
                >
                  <img
                    src="css/images/cliente09.png"
                    className="mark-tab"
                    alt="walliaguess"
                  />
                </div>
                <div
                  id="tab3"
                  className="marca-tab4"
                  onClick={() => slideToSlide(3)}
                >
                  <img
                    src="css/images/MicrosoftTeams-image (2).png"
                    className="mark-tab"
                    alt="walliatommy"
                  />
                </div>
                <div
                  id="tab4"
                  className="marca-tab5"
                  onClick={() => slideToSlide(4)}
                >
                  <img
                    src="css/images/marca-calvin_.png"
                    className="mark-tab"
                    alt="walliacalvin"
                  />
                </div>
                <div
                  id="tab5"
                  className="marca-tab6"
                  onClick={() => slideToSlide(5)}
                >
                  <img
                    src="css/images/cliente10.png"
                    className="mark-tab"
                    alt="walliataff"
                  />
                </div>
                {/* <img src="css/images/flecha-right_.png" class="flecha-cliente-right"   />	 */}
              </article>

              <article class="info-marca">
                {/* {modalMsg} */}
                <Swiper
                  ref={swiperRef}
                  modules={[Autoplay, Navigation, Scrollbar]}
                  centeredSlides={true}
                  //autoplay={{
                  // delay: 8000,
                  // disableOnInteraction: false

                  //}}
                  onSlideChange={(e) => {
                    for (let i = 0; i < 6; i++) {
                      document.getElementById(
                        "tab" + i
                      ).className = `marca-tab${i + 1} ${
                        i == e.activeIndex ? "active-tab" : ""
                      }`;
                    }
                  }}
                  pagination={{ clickable: true }}
                  navigation={true}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <article class="box-tab-marca">
                      <img
                        src="css/images/petco.png"
                        class="image-petco"
                        alt=""
                      />
                    </article>
                    <article class="box-tab-marca1 prev">
                      <p class="title-marca-gray">PETCO</p>
                      <p class="app">
                        {language ? json.En.appNative : json.Es.appNative}
                      </p>
                      <p class="app-system">
                        {language
                          ? json.En.SistemIOSANDROID
                          : json.Es.SistemIOSANDROID}
                      </p>
                      <p class="text-descripcion">
                        {language
                          ? json.En.DescriptionMobile
                          : json.Es.DescriptionMobile}
                      </p>
                      <div class="bullets-marca">
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          E-commerce
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          Easy Buy
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Management : json.Es.Management}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Loyalty : json.Es.Loyalty}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Location : json.Es.Location}
                        </p>
                      </div>
                      <article class="detalle-cliente">
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion1_.png"
                            class="icon-detalle"
                            alt="walliaflujo"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile1
                              : json.Es.TextDetailMobile1}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion2_.png"
                            class="icon-detalle"
                            alt="walliaprecio"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile2
                              : json.Es.TextDetailMobile2}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion3_.png"
                            class="icon-detalle"
                            alt="walliabusqueda"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile3
                              : json.Es.TextDetailMobile3}
                          </p>
                        </div>
                      </article>
                    </article>
                  </SwiperSlide>
                  <SwiperSlide>
                    <article class="box-tab-marca">
                      <img
                        src="css/images/shake.png"
                        class="image-petco"
                        alt=""
                      />
                    </article>
                    <article class="box-tab-marca1 next">
                      <p class="title-marca-gray">SHACKESHAK</p>
                      <p class="app">
                        {language ? json.En.appNative : json.Es.appNative}
                      </p>
                      <p class="app-system">
                        {language
                          ? json.En.SistemIOSANDROID
                          : json.Es.SistemIOSANDROID}
                      </p>
                      <p class="text-descripcion">
                        {language
                          ? json.En.DescriptionMobile
                          : json.Es.DescriptionMobile}
                      </p>
                      <div class="bullets-marca">
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          E-commerce
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          Easy Buy
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Management : json.Es.Management}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Loyalty : json.Es.Loyalty}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Location : json.Es.Location}
                        </p>
                      </div>
                      <article class="detalle-cliente">
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion1_.png"
                            class="icon-detalle"
                            alt="walliaflujo"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile1
                              : json.Es.TextDetailMobile1}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion2_.png"
                            class="icon-detalle"
                            alt="walliaprecio"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile2
                              : json.Es.TextDetailMobile2}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion3_.png"
                            class="icon-detalle"
                            alt="walliabusqueda"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile3
                              : json.Es.TextDetailMobile3}
                          </p>
                        </div>
                      </article>
                    </article>
                  </SwiperSlide>
                  <SwiperSlide>
                    <article class="box-tab-marca">
                      <img
                        src="css/images/guess.png"
                        class="image-petco"
                        alt=""
                      />
                    </article>
                    <article class="box-tab-marca1">
                      <p class="title-marca-gray">GUESS</p>
                      <p class="app">
                        {language ? json.En.appNative : json.Es.appNative}
                      </p>
                      <p class="app-system">
                        {language
                          ? json.En.SistemIOSANDROID
                          : json.Es.SistemIOSANDROID}
                      </p>
                      <p class="text-descripcion">
                        {language
                          ? json.En.DescriptionMobile
                          : json.Es.DescriptionMobile}
                      </p>
                      <div class="bullets-marca">
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          E-commerce
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          Easy Buy
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Management : json.Es.Management}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Loyalty : json.Es.Loyalty}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Location : json.Es.Location}
                        </p>
                      </div>
                      <article class="detalle-cliente">
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion1_.png"
                            class="icon-detalle"
                            alt="walliaflujo"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile1
                              : json.Es.TextDetailMobile1}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion2_.png"
                            class="icon-detalle"
                            alt="walliaprecio"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile2
                              : json.Es.TextDetailMobile2}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion3_.png"
                            class="icon-detalle"
                            alt="walliabusqueda"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile3
                              : json.Es.TextDetailMobile3}
                          </p>
                        </div>
                      </article>
                    </article>
                  </SwiperSlide>
                  <SwiperSlide>
                    <article class="box-tab-marca">
                      <img
                        src="css/images/tommy.png"
                        class="image-petco"
                        alt=""
                      />
                    </article>
                    <article class="box-tab-marca1">
                      <p class="title-marca-gray">TOMMY</p>
                      <p class="app">
                        {language ? json.En.appNative : json.Es.appNative}
                      </p>
                      <p class="app-system">
                        {language
                          ? json.En.SistemIOSANDROID
                          : json.Es.SistemIOSANDROID}
                      </p>
                      <p class="text-descripcion">
                        {language
                          ? json.En.DescriptionMobile
                          : json.Es.DescriptionMobile}
                      </p>
                      <div class="bullets-marca">
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          E-commerce
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          Easy Buy
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Management : json.Es.Management}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Loyalty : json.Es.Loyalty}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Location : json.Es.Location}
                        </p>
                      </div>
                      <article class="detalle-cliente">
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion1_.png"
                            class="icon-detalle"
                            alt="walliaflujo"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile1
                              : json.Es.TextDetailMobile1}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion2_.png"
                            class="icon-detalle"
                            alt="walliaprecio"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile2
                              : json.Es.TextDetailMobile2}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion3_.png"
                            class="icon-detalle"
                            alt="walliabusqueda"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile3
                              : json.Es.TextDetailMobile3}
                          </p>
                        </div>
                      </article>
                    </article>
                  </SwiperSlide>
                  <SwiperSlide>
                    <article class="box-tab-marca">
                      <img
                        src="css/images/calvin.png"
                        class="image-petco"
                        alt=""
                      />
                    </article>
                    <article class="box-tab-marca1">
                      <p class="title-marca-gray">CALVIN KLEIN</p>
                      <p class="app">
                        {language ? json.En.appNative : json.Es.appNative}
                      </p>
                      <p class="app-system">
                        {language
                          ? json.En.SistemIOSANDROID
                          : json.Es.SistemIOSANDROID}
                      </p>
                      <p class="text-descripcion">
                        {language
                          ? json.En.DescriptionMobile
                          : json.Es.DescriptionMobile}
                      </p>
                      <div class="bullets-marca">
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          E-commerce
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          Easy Buy
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Management : json.Es.Management}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Loyalty : json.Es.Loyalty}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Location : json.Es.Location}
                        </p>
                      </div>
                      <article class="detalle-cliente">
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion1_.png"
                            class="icon-detalle"
                            alt="walliaflujo"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile1
                              : json.Es.TextDetailMobile1}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion2_.png"
                            class="icon-detalle"
                            alt="walliaprecio"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile2
                              : json.Es.TextDetailMobile2}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion3_.png"
                            class="icon-detalle"
                            alt="walliabusqueda"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile3
                              : json.Es.TextDetailMobile3}
                          </p>
                        </div>
                      </article>
                    </article>
                  </SwiperSlide>
                  <SwiperSlide>
                    <article class="box-tab-marca">
                      <img
                        src="css/images/taff.png"
                        class="image-petco"
                        alt=""
                      />
                    </article>
                    <article class="box-tab-marca1">
                      <p class="title-marca-gray">TAF</p>
                      <p class="app">
                        {language ? json.En.appNative : json.Es.appNative}
                      </p>
                      <p class="app-system">
                        {language
                          ? json.En.SistemIOSANDROID
                          : json.Es.SistemIOSANDROID}
                      </p>
                      <p class="text-descripcion">
                        {language
                          ? json.En.DescriptionMobile
                          : json.Es.DescriptionMobile}
                      </p>
                      <div class="bullets-marca">
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          E-commerce
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          Easy Buy
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Management : json.Es.Management}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Loyalty : json.Es.Loyalty}
                        </p>
                        <p class="bullet-descrip">
                          <img
                            src="css/images/check-blue_.png"
                            class="icon-check"
                            alt="walliacheck"
                          />
                          {language ? json.En.Location : json.Es.Location}
                        </p>
                      </div>
                      <article class="detalle-cliente">
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion1_.png"
                            class="icon-detalle"
                            alt="walliaflujo"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile1
                              : json.Es.TextDetailMobile1}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion2_.png"
                            class="icon-detalle"
                            alt="walliaprecio"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile2
                              : json.Es.TextDetailMobile2}
                          </p>
                        </div>
                        <div class="box-detalle">
                          <img
                            src="css/images/icon-descripcion3_.png"
                            class="icon-detalle"
                            alt="walliabusqueda"
                          />
                          <p class="text-detalle">
                            {language
                              ? json.En.TextDetailMobile3
                              : json.Es.TextDetailMobile3}
                          </p>
                        </div>
                      </article>
                    </article>
                  </SwiperSlide>
                </Swiper>
              </article>
            </article>
          </section>
          <section class="contenido-cliente-product">
            <h2>{language ? json.En.h2Clients : json.Es.h2Clients}:</h2>
            <article class="box-product-cliente">
              <div class="box-product-client">
                <img
                  src="css/images/icon-product-client1_.png"
                  class="icon-prod"
                  alt="walliadesarrollos"
                />
                <p class="product-title-icon">
                  {language
                    ? json.En.productTitleClients
                    : json.Es.productTitleClients}
                </p>
                <p class="product-text-icon">
                  {language
                    ? json.En.productTextClients
                    : json.Es.productTextClients}
                </p>
              </div>
              <div class="box-product-client">
                <img
                  src="css/images/icon-product-client2_.png"
                  class="icon-prod"
                  alt="wallliaintegracion"
                />
                <p class="product-title-icon">
                  {language
                    ? json.En.productTitleClients2
                    : json.Es.productTitleClients2}
                </p>
                <p class="product-text-icon">
                  {language
                    ? json.En.productTextClients2
                    : json.Es.productTextClients2}
                </p>
              </div>
              <div class="box-product-client">
                <img
                  src="css/images/icon-product-client3_.png"
                  class="icon-prod"
                  alt="walliareferencia"
                />
                <p class="product-title-icon">
                  {language
                    ? json.En.productTitleClients3
                    : json.Es.productTitleClients3}
                </p>
                <p class="product-text-icon">
                  {language
                    ? json.En.productTextClients3
                    : json.Es.productTextClients3}
                </p>
              </div>
            </article>
          </section>
          <section class="contact-section">
            <h2>{language ? json.En.contact : json.Es.contact}</h2>
            <form class="form-contact">
              <div class="campo">
                <label for="fname" class="label-contact">
                  {language ? json.En.name : json.Es.name}
                </label>
                <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  class="campo-contact"
                  ref={nameRef}
                  onChange={handleChange}
                />
              </div>
              <div class="campo">
                <label for="email" class="label-contact">
                  {language ? json.En.email : json.Es.email}
                </label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="campo-contact"
                  ref={sendtoRef}
                  onChange={handleChange}
                />
              </div>
              <div class="campo">
                <label for="digital" class="label-contact">
                  {language ? json.En.title : json.Es.title}
                </label>
                <br />
                <select
                  id="contact"
                  name="digital"
                  ref={titleRef}
                  onChange={handleChange}
                >
                  <option value="app">
                    {language ? json.En.appMobile : json.Es.appMobile}
                  </option>
                  <option value="web">
                    {language ? json.En.web : json.Es.web}
                  </option>
                  <option value="solution">
                    {language ? json.En.solition : json.Es.solition}
                  </option>
                  <option value="adata">
                    {language ? json.En.data : json.Es.data}
                  </option>
                </select>
              </div>
              <div class="campo">
                <input
                value={language ? json.En.send : json.Es.send}
                  onClick={sendMail}
                  style={{ cursor: "pointer" }}
                  class="enviar"
                /> 
              </div>
            </form>
          </section>
        </section>
      </section>
    </>
  );
}
