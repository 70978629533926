import React, { useRef, useState } from "react";
import func from "./functionAdd";
import json from "../json.json";
import Boxes from "./boxes";
import Hero from "./elements/Hero";

export default function Interns() {
  const nameRef = useRef();
  const messageRef = useRef();
  const sendtoRef = useRef();
  const universityRef = useRef();
  const carreraRef = useRef();
  const telefonoRef = useRef();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [language, setLanguage] = useState();
  const handleLocalStorage = () => {
    let es = localStorage.getItem("language");
    if (es === "true") {
      setLanguage(false);
    } else {
      setLanguage(true);
    }
  };
  setTimeout(handleLocalStorage, 1);

  const [onModal1, setOnModal1] = useState(false);
  const [onTypeModal1, setOnTypeModal1] = useState("");
  let modalSend = onModal1 ? (
    <Boxes setOnModal={setOnModal1} type={onTypeModal1} data={""} />
  ) : (
    ""
  );
  const modalErrorMail = () => {
    setOnTypeModal1("modal2");
    setOnModal1(true);
  };

  const modalEmail = () => {
    setOnTypeModal1("modal");
    setOnModal1(true);
  };

  const sendMailt = async () => {
    if (
      nameRef.current.value === "" ||
      messageRef.current.value === "" ||
      sendtoRef.current.value === "" ||
      !sendtoRef.current.value.includes("@") ||
      universityRef.current.value === "" ||
      carreraRef.current.value === "" ||
      telefonoRef.current.value === ""
    ) {
      setFormState((formState) => ({
        ...formState,
        errors: {
          ...formState.errors,
          [nameRef.current.name]: nameRef.current.value === "",
          [messageRef.current.name]: messageRef.current.value === "",
          [sendtoRef.current.name]:
            sendtoRef.current.value === "" ||
            !sendtoRef.current.value.includes("@") ||
            !sendtoRef.current.value.includes(".com"),
          [universityRef.current.name]: universityRef.current.value === "",
          [carreraRef.current.name]: carreraRef.current.value === "",
          [telefonoRef.current.name]: telefonoRef.current.value === "",
        },
      }));
      modalErrorMail();
      return;
    } else {
      const paramsToSend = {
        email: sendtoRef.current.value,
        name: nameRef.current.value,
        university: universityRef.current.value,
        aboutMe: messageRef.current.value,
        career: carreraRef.current.value,
        phoneNumber: telefonoRef.current.value,
      };
      fetch(
        "https://oleihwnf39.execute-api.us-east-2.amazonaws.com/prod/save-info",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "bw-api-key": "xl4KzBxnD7eLSTE",
          },
          body: JSON.stringify(paramsToSend),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          modalEmail();
          //limpiar campos
          nameRef.current.value = "";
          messageRef.current.value = "";
          sendtoRef.current.value = "";
          universityRef.current.value = "";
          carreraRef.current.value = "";
          telefonoRef.current.value = "";
        })
        .catch((err) => console.log(err));
      /*      await func.EjectFetch("apimailer/index.php", param); */
      /*    modalEmail(); */
    }
  };

  return (
    <>
      {modalSend}
      <section class="contenido">
        <section class="contacto-datos">
          <article class="cajas-contacto">
            <article class="container-interns">
              <Hero />
              <article class="form-fondo">
                <form class="form-contact-datos">
                  <div class="campo-datos">
                    <label for="fname" class="label-contact-datos">
                      {language ? json.En.name : json.Es.name}
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="name"
                      class="campo-contact-datos"
                      ref={nameRef}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="campo-datos">
                    <label for="email" class="label-contact-datos">
                      {language ? json.En.email : json.Es.email}
                    </label>
                    <br />
                    <input
                      type="email"
                      id="email"
                      name="sendto"
                      class="campo-contact-datos"
                      ref={sendtoRef}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="campo-datos">
                    <label for="universidad" class="label-contact-datos">
                      {language ? json.En.universidad : json.Es.universidad}
                    </label>
                    <br />
                    <input
                      type="text"
                      id="university"
                      name="sendto"
                      class="campo-contact-datos"
                      ref={universityRef}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="campo-datos">
                    <label for="carrera" class="label-contact-datos">
                      {language ? json.En.carrera : json.Es.carrera}
                    </label>
                    <br />
                    <input
                      type="text"
                      id="carrera"
                      name="sendto"
                      class="campo-contact-datos"
                      ref={carreraRef}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="campo-datos">
                    <label for="carrera" class="label-contact-datos">
                      {language ? json.En.telefono : json.Es.telefono}
                    </label>
                    <br />
                    <input
                      type="text"
                      id="telefono"
                      name="telefono"
                      class="campo-contact-datos"
                      ref={telefonoRef}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="campo-datos">
                    <label for="message" class="label-contact-datos">
                      {language ? json.En.about : json.Es.about}
                    </label>
                    <br />
                    <textarea
                      name="message"
                      rows="10"
                      cols="30"
                      class="campo-contact-datos-mensaje"
                      ref={messageRef}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div class="campo-datos">
                    <input
                      value={language ? json.En.send : json.Es.send}
                      onClick={sendMailt}
                      class="enviar-datos"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </form>
              </article>
            </article>
          </article>
        </section>
      </section>
    </>
  );
}
